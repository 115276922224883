*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Nunito', sans-serif !important;
  background-color: #f8f9fb;
}

ul{
  padding: 0;
  margin: 0;
}

li{
  list-style-type: none;
}

p{
  color: #6c757d;
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 15px;
}


@media (max-width:767px){
  .sidenav{
    display: none;
  }

  .certificate-search{
    width: 100% !important;
    text-align: left !important;
  }
}

@media (min-width:768px) and (max-width: 1100px){
  /* .main-cont-section{
    padding-left: 120px;
  } */
}