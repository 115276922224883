.header-section{
    box-shadow: 0 8px 16px rgba(0,0,0,.15);
    padding: 10px 0;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 22;
    background-color: #fff;
}

.header-section .logo{
    width: 140px;
}

.reg-btn{
    padding: 8px 15px;
    border: none;
    box-shadow: none;
    margin-left: 15px;
    font-size: 15px;
    background-color: transparent;
}

.btn-bg{
    background-color: #670099;
    color: #fff;
    border-radius: 5px;
}

.navbar-nav .nav-item .nav-link{
    color: #1b2733;
    padding: 5px 15px;
    display: inline-block;
}

.navbar-toggler{
    border: none;
}

.navbar-toggler:focus{
    outline: none;
    box-shadow: none;
}