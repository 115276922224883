.footer-section{
    border-top: 1px solid #ddd;
    background: #fff;
    padding: 30px 20px;
    box-shadow: 0 2px 8px rgb(221 220 220);
}
.footer-section .ftr-logo{
    width: 180px;   
}

.ftr-links{
    margin-bottom: 30px;
}

.ftr-links h3{
    color: #000;
    font-size: 18px;
    font-weight: 800;
    margin-bottom: 20px;
}

.ftr-links ul li a{
    text-decoration: none;
    color: #6c757d;
    margin-bottom: 10px;
    display: block;
}

.ftr-phone{
    color: #670099;
    font-weight: 600;
}