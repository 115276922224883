
.sidenav{
    width: 100px;
    border-right: 1px solid #e3e3e3;
    height: 100vh;
    position: fixed;
    top: 60px;
    left: 0;
}

.sidenav ul li a{
    padding: 30px 10px;
    display: block;
    border-bottom: 1px solid #e3e3e3;
    text-align: center;
    font-size: 20px;
    transition: all 0.5s ease;
    color: #707070;
}

.sidenav ul li a:hover{
    background-color: #670099;
    color: #fff;
}