* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

@font-face {
    font-family: 'BrittanySignature';
    src: url('../../assets/fonts/BrittanySignature.ttf');
    font-weight: normal;
    font-style: normal;
}

.certificate-create-section {
    width: 95%;
    margin: 0 auto;
    padding: 50px 40px;
    background: url('../../assets/images/bg-shape.png') repeat;
    background-size: 90px;
    height: auto;
    box-shadow: 0 2px 5px rgb(221 220 220);
    position: relative;
    z-index: 2;
}





.certificate-create-section::before {
    content: "";
    position: absolute;
    width: 94%;
    height: 94%;
    transform: translate(3%, 3%);
    border: 1px solid #ddd;
    left: 0;
    top: 0;
}

.certificate-create-section:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(255 255 255 / 63%);
    z-index: -1;
}

.main-cont .name {
    font-family: 'BrittanySignature';
    font-size: 2.5vw;
    margin-bottom: 30px;
}

.main-cont .role {
    background-color: #a020f0;
    color: #fff;
    text-transform: uppercase;
    display: inline-block;
    padding: 5px 10px;
    font-size: 0.9vw;
    font-weight: 500;
    margin-bottom: 20px;
    font-family: 'Oswald', sans-serif;
}

.main-cont {
    padding: 40px 10px;
}

.main-cont .cont {
    font-size: 0.9vw;
    font-weight: 500;
    width: 95%;
    margin: auto;
    margin-bottom: 20px;
    font-family: 'Montserrat', sans-serif;
    color: #000;
}

.certificate-id h4 {
    color: #a020f0;
    font-weight: 500;
    font-size: 0.9vw;
    font-family: 'Montserrat', sans-serif;
    margin-bottom: 5px;
}

.certificate-id p {
    font-size: 0.75vw;
    font-weight: 700;
    color: #000;
    font-family: 'Montserrat', sans-serif;
}

.sign-sec {
    text-align: center;
    position: relative;
    top: 0px;
}

.sign-sec h2 {
    font-size: 1.2vw;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    margin-bottom: 8px;
}

.sign-sec p {
    font-size: 0.9vw;
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    color: #000;
}

.qr-sec h4 {
    color: #a020f0;
    font-weight: 500;
    font-size: 0.9vw;
    font-family: 'Montserrat', sans-serif;
    margin-bottom: 5px;
}

.qr-sec p a {
    font-size: .75vw;
    font-weight: 600;
    color: #000;
    font-family: 'Montserrat', sans-serif;
    text-decoration: none;
}

.qr-img {
    width: 5vw;
    margin: auto;
}

.sign-img {
    width: 10vw;
    margin: auto;
}

.img-1 {
    width: 10vw;
}

.img-2 {
    width: 10vw;
    margin-left: auto;
}

.coursename {
    text-transform: capitalize;
}

.certificate-err-sec {
    border: 1px solid #ddd;
    text-align: center;
    width: 95%;
    height: 590px;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 2px 5px rgb(221 220 220);
    background-color: #fff;
    /* background: url('../../assets/images/bg-img.jpg') no-repeat;
    background-size: 100% 100%;
    background-position: 100% 100%; */
}

.certificate-err-sec img {
    width: 50px;
}


@media (max-width:400px) {
    .certificate-create-section {
        width: 300px;
        margin: 20px auto;
        padding: 20px;
        background-size: 100% 100%;
    }

    iframe {
        width: 100%;
        height: 274px !important;
        border: none;
    }

    .main-cont {
        padding: 10px 0;
    }

    .main-cont .cont {
        width: 100%;
    }

    .main-cont .name {
        margin-bottom: 10px;
        font-size: 5vw;
    }

    .main-cont .role {
        margin-bottom: 10px;
        font-size: 1vw;
    }

    .main-cont .cont {
        font-size: 1.7vw;
        margin-bottom: 15px;
    }

    .sign-sec {
        top: -10px;
    }

    .qr-sec p {
        font-size: 1vw;
    }

    .certificate-dt-cont h1 {
        font-size: 25px;
    }

    .certificate-err-sec {
        height: 280px;
    }
}

@media (min-width:401px) and (max-width:767px) {
    .certificate-create-section {
        width: 100%;
        margin: 20px auto;
        padding: 20px 20px;
        background-size: 50px;
    }

    .main-cont {
        padding: 10px;
    }

    .main-cont .cont {
        width: 96%;
    }

    .main-cont .name {
        margin-bottom: 10px;
        font-size: 3.5vw;
    }

    .main-cont .role {
        margin-bottom: 10px;
        font-size: 1vw;
    }

    .main-cont .cont {
        font-size: 1.7vw;
        margin-bottom: 15px;
    }

    .sign-sec {
        top: -10px;
    }

    .qr-sec p {
        font-size: 1vw;
    }

    .certificate-dt-cont h1 {
        font-size: 25px;
    }

    .certificate-err-sec {
        height: 280px;
    }
}

@media (min-width:768px) and (max-width:991px) {
    .certificate-create-section {
        width: 450px;
        background-size: 100% 100%;
        padding: 40px 30px;
    }

    .main-cont {
        padding: 30px 20px 20px;
    }

    .main-cont .cont {
        width: 100%;
    }

    .main-cont .name {
        margin-bottom: 10px;
    }

    .sign-sec p {
        font-size: 0.9vw;
    }

    .certificate-dt-cont h1 {
        font-size: 30px;
    }

    .certificate-err-sec {
        width: 450px;
        height: 400px;
    }
}

@media (min-width:992px) and (max-width:1200px) {
    .certificate-create-section {
        width: 90%;
        margin: 20px auto;
        padding: 35px 20px;
        background-size: 100% 100%;
    }

    .main-cont {
        padding: 20px 10px;
    }

    .main-cont .cont {
        width: 98%;
    }

    .main-cont .name {
        margin-bottom: 10px;
        font-size: 2vw;
    }

    .main-cont .role {
        margin-bottom: 10px;
        font-size: 0.7vw;
    }

    .main-cont .cont {
        font-size: 0.8vw;
        margin-bottom: 15px;
    }

    .certificate-id h4,
    .qr-sec h4 {
        font-size: 0.7vw;
    }

    .certificate-id p {
        font-size: 0.6vw;
    }

    .sign-sec p {
        font-size: 0.75vw;
    }

    .qr-sec p a {
        font-size: 0.6vw;
    }

    .qr-sec p {
        font-size: 1vw;
    }

    .qr-img {
        width: 4vw;
        margin: auto;
    }

    .sign-img {
        width: 8vw;
        margin: auto;
    }

    .img-1 {
        width: 8vw;
    }

    .img-2 {
        width: 7vw;
        margin-left: auto;
    }
}