.tooltip-container{
    position: relative;
    display: inline-block;
    /* width: 70%; */
    margin-left: 5px;
}

.tooltip-container i{
    cursor: pointer;
    font-size: 18px;
}

.tooltips{
    position: absolute;
    top: 50%;
    left: 22px;
    z-index: 2;
    background: #fff;
    padding: 10px 10px;
    border: 1px solid #ddd;
    transform: translateY(-50%);
    border-radius: 3px;
    width: 200px;
}

.tooltips h3{
    font-size: 14px;
    color: #000;
    font-weight: 700;
}

.tooltips p{
    font-size: 12px;
    margin: 0;
}



.tooltips:after{
    content: "\f104";
    position: absolute;
    font-family: FontAwesome;
    font-style: normal;
    font-weight: normal;
    text-decoration: inherit;
    opacity: 0;
    left: -6px;
    color: #ddd;
    top: 50%;
    transform: translateY(-50%);
    font-size: 16px;
}

.tooltip-container:hover .tooltips:after{
    opacity: 1;
}