.certificate-section{
    padding-top: 0px;
    padding-bottom: 30px;
}

.certificate-search{
    width: 50%;
    margin: auto;
    text-align: center;
    padding-top: 130px;
    padding-bottom: 70px;
}

.certificate-dt-cont h1{
    font-size: 40px;
    font-weight: 800;
    color: #000;
    margin-bottom: 20px;
}

.certificate-dt-cont h1 i{
    font-size: 13px;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    line-height: 22px;
    background-color: #000;
    color: #fff;
    text-align: center;
    vertical-align: middle;
}

.input-name{
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 15px;
    color: #000;
}

.form-control:focus{
    box-shadow: none;
    outline: none;
    color: #670099;
}

.certificate-img{
    margin-bottom: 30px;
}

.min-wid{
    min-width: 120px;
}

.share-certificate{
    margin-bottom: 40px;
}

.share-icon{
    display: inline-block;
    width: 45px;
    height: 45px;
    line-height: 45px;
    text-align: center;
    color: #fff;
    margin-right: 10px;
    font-size: 20px;
    border-radius: 50%;
}

.icon-1{
    background-color: #0076b2;
}

.icon-2{
    background-color: #3a599a;
}

.icon-3{
    background-color: #1ea0f6;
}

.icon-4{
    background-color: #bd3827;
}

.sec-title{
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 15px;
    /* color: #670099; */
    color: #000;
    margin-bottom: 20px;
}

.faq{
    padding: 50px 0;
}

.faq .accordion-item{
    border: none;
    background-color: #fff;
    width: 95%;
    color: #6c757d;
    margin-bottom: 15px;
}

.faq .accordion-item p{
    margin-bottom: 0;
}

.faq .accordion .accordion-button{
    padding: 14px 40px;
    box-shadow: 0 2px 5px rgb(221 220 220);
    background-color: #fff;
    border-radius: 0;
    color: #052c65;
    font-weight: bold;
}

.faq .accordion .accordion-button::after{
    content: "\f078";
    font-family: FontAwesome;
    font-style: normal;
    font-weight: normal;
    text-decoration: inherit;
    color: #052c65;
    font-size: 16px;
    padding-right: 0.5em;
    position: absolute;
    top: 12px;
    left: 12px;
    background: none;
}


.accordion-item:last-of-type .accordion-button.collapsed{
    border-radius: 0;
}

.table.certificate-table tr th{
    font-weight: bold;
    width: 40%;
}

.table.certificate-table tr th, td{
    font-size: 15px;
    color: #6c757d;
    border: 1px solid rgb(204 204 204 / 44%);
}

.table.certificate-table tr td{
    color: #6c757d;
}

.table.certificate-table tr:nth-child(even){
    background-color: rgba(0, 0, 0, 0.03);
}

.table.certificate-table tr th{
    background-color: transparent;
}

.table.certificate-table tr td{
    background-color: transparent;
    text-transform: capitalize;
}

.search-container{
    position: relative;
    padding: 0;
    background: #fff;
    overflow: hidden;
    border-radius: 25px;
    border: solid 1px #ccc;
}
.search-input{
    color: #670099;
    font-weight: 700;
    height: 40px;
    margin-bottom: 0 !important;
    border: 0;
    padding-right: 90px;
}

.search-input::placeholder{
    opacity: 0.5;
}
.search-btn{
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    border-radius: 0;
    min-width: 80px;
}
/* .faq-list{
    width: 50%;
    margin: auto;
} */

.table-content{
    background-color: #fff;
    box-shadow: 0 3px 10px rgb(221 220 220);
    padding: 20px 15px 10px 15px;
    border-radius: 5px;
    margin-bottom: 20px;
    overflow-x: auto;
}

.err-msg{
    color: red;
}

.accordion-body{
    background-color: #f8f9fb;
}